<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
	  return {}
  },
  beforeCreate() {
  	console.time('time')
  },
  mounted() {
  	console.timeEnd('time')
  }
}
</script>

<style>
html,
body,
#app,
#app>section{
	min-height: 100%;
}
body{
    background-color: #f1f2f7;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
	min-width: 300px;
  }
  .green{
  	  background: linear-gradient(to right, #36dd7f, #13ba5c);
  }
  .gray{
  	  background: linear-gradient(to right, #c1d1dd, #818c94);
  }
  .orange{ 
  	  background: linear-gradient(to right, #f3602e, #eb5c2c); 
  }
  .blue{
  	  background: linear-gradient(to right, #0062ff, #005ae2); 
  }
  .Cyan{
  	  background: linear-gradient(to right, #21f4ff, #1ee2e8);
  }
  .purple{
  	  background: linear-gradient(to right, #ff40f0, #d836ce);
  }
  .yellow{
  	  background: linear-gradient(to right, #f0cd07, #d8d800);
  }
  .black{
	  background: linear-gradient(to right, #585858, #1a1a1a);
  }
  .red{
	  background: linear-gradient(to right, #ef0000, #d90000);
  }
  .LightBlue{
	  background: linear-gradient(to right, #2b9cff, #278ee8); 
  }
  .text_green{
	  color: #36dd7f;
  }
  .text_yellow{
  	  color: #d8d800;
  }
  .text_orange{
  	  color: #f3602e;
  }
  .text_red{
  	  color: #ef0000;
  }
</style>
