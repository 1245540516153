import Vue from 'vue'
import {
  Button, Row, Col, Menu, Submenu,
  MenuItemGroup, MenuItem, Container, Header, Aside,
  Main, Image, Dropdown, DropdownMenu, DropdownItem,
  Tooltip, Card, DatePicker, Alert, Form,
  FormItem, Input, Option, Select, Checkbox,
  CheckboxGroup, RadioGroup, Radio, Switch,
  TimePicker, Pagination, Footer, Collapse, CollapseItem,
  Dialog, Autocomplete,Upload, Tree, Table,
  TableColumn,Notification, Skeleton, Badge,
  Drawer,Message,Loading,MessageBox,TimeSelect,SkeletonItem 
} from 'element-ui'

Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Image)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tooltip)
Vue.use(Card)
Vue.use(DatePicker)
Vue.use(Alert)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Option)
Vue.use(Select)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Switch)
Vue.use(TimePicker)
Vue.use(Pagination)
Vue.use(Footer)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Dialog)
Vue.use(Autocomplete)
Vue.use(Upload)
Vue.use(Tree)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Skeleton)
Vue.use(Badge)
Vue.use(Drawer)
Vue.use(Loading)
Vue.use(TimeSelect)
Vue.use(SkeletonItem)
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
Vue.prototype.$confirm  = MessageBox.confirm
Vue.prototype.$prompt  = MessageBox.prompt
Vue.prototype.$loading = Loading.service