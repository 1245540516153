import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
//import Home from '../views/Home.vue'

const routes = [{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login.vue')
	},
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue'),
		redirect: '/index',
		children: [{
				path: '/index',
				name: 'Index',
				component: () => import('../views/index/index.vue')
			},
			{
				path: '/addsite',
				name: 'AddSite',
				component: () => import('../views/site/addSite.vue')
			},
			{
				path: '/editSite',
				name: 'editSite',
				component: () => import('../views/site/editSite.vue')
			},
			{
				path: '/dataAna',
				name: 'dataAna',
				component: () => import('../views/site/DataAna.vue')
			},
			{
				path: '/sitemessage',
				name: 'SiteMessage',
				component: () => import('../views/site/siteMessage.vue'),
				meta: {
				   keepAlive: true
				}
			},
			{
				path: '/editCharging',
				name: 'editCharging',
				component: () => import('../views/chargingPile/editCharging.vue')
			},
			{
				path: '/addCharging',
				name: 'AddCharging',
				component: () => import('../views/chargingPile/addCharging.vue')
			},
			{
				path: '/gunLog',
				name: 'gunLog',
				component: () => import('../views/chargingPile/gunLog.vue')
			},
			{
				path: '/pileParams',
				name: 'pileParams',
				component: () => import('../views/chargingPile/pileParams.vue')
			},
			{
				path: '/ChargingMessage',
				name: 'ChargingMessage',
				component: () => import('../views/chargingPile/ChargingMessage.vue'),
				meta: {
				   keepAlive: true
				}
			},
			{
				path: '/gunMessage',
				name: 'GunMessage',
				component: () => import('../views/chargingPile/gunMessage.vue')
			},
			{
				path: '/PileQRcode',
				name: 'PlieQRcode',
				component: () => import('../views/chargingPile/PileQRcode.vue')
			},
			{
				path: '/lnvoicing',
				name: 'Lnvoicing',
				component: () => import('../views/business/lnvoicing.vue')
			},
			{
				path: '/operateShop',
				name: 'OperateShop',
				component: () => import('../views/business/operateShop.vue')
			},
			{
				path: '/ReportDownload',
				name: 'ReportDownload',
				component: () => import('../views/business/ReportDownload.vue')
			},
			{
				path: '/vehicle',
				name: 'Vehicle',
				component: () => import('../views/business/vehicle.vue')
			},
			{
				path: '/vip',
				name: 'Vip',
				component: () => import('../views/business/vip.vue'),
				meta: {
				   keepAlive: true
				}
			},
			{
				path: '/vipDet',
				name: 'vipDet',
				component: () => import('../views/business/vipDet.vue')
			},
			{
				path: '/changeRecord',
				name: 'changeRecord',
				component: () => import('../views/business/changeRecord.vue')
			},
			{
				path: '/recycle',
				name: 'recycle',
				component: () => import('../views/business/recycle.vue')
			},
			{
				path: '/RefundRecord',
				name: 'RefundRecord',
				component: () => import('../views/business/RefundRecord.vue')
			},
			{
				path: '/readyPayOrder',
				name: 'readyPayOrder',
				component: () => import('../views/business/readyPayOrder.vue')
			},
			{
				path: '/readyPayRefund',
				name: 'readyPayRefund',
				component: () => import('../views/business/readyPayRefund.vue')
			},
			{
				path: '/Customize',
				name: 'Customize',
				component: () => import('../views/business/Customize.vue')
			},
			{
				path: '/changeExa',
				name: 'changeExa',
				component: () => import('../views/business/changeExa.vue')
			},
			{
				path: '/InvoiceDet',
				name: 'InvoiceDet',
				component: () => import('../views/business/InvoiceDet.vue')
			},
			{
				path: '/calculate',
				name: 'Calculate',
				component: () => import('../views/operate/calculate.vue')
			},
			{
				path: '/ElectricCharg',
				name: 'ElectricCharg',
				component: () => import('../views/operate/ElectricCharg.vue'),
				meta: {
				   keepAlive: true
				}
			},
			{
				path: '/feedback',
				name: 'FeedBack',
				component: () => import('../views/operate/feedback.vue')
			},
			{
				path: '/order',
				name: 'Order',
				component: () => import('../views/operate/order.vue'),
				meta: {
				   keepAlive: true
				}
			},
			{
				path: '/orderDet',
				name: 'orderDet',
				component: () => import('../views/operate/orderDet.vue')
			},
			{
				path: '/Recharge',
				name: 'Recharge',
				component: () => import('../views/operate/Recharge.vue')
			},
			{
				path: '/warning',
				name: 'Warning',
				component: () => import('../views/operate/warning.vue')
			},
			{
				path: '/refund',
				name: 'Refund',
				component: () => import('../views/operate/refund.vue')
			},
			{
				path: '/RechargeActivity',
				name: 'RechargeActivity',
				component: () => import('../views/operate/RechargeActivity.vue')
			},
			{
				path: '/parkingLot',
				name: 'ParkingLot',
				component: () => import('../views/operate/parkingLot.vue'),
				meta: {
				   keepAlive: true
				}
			},
			{
				path: '/parkingOS',
				name: 'parkingOS',
				component: () => import('../views/operate/parkingOS.vue')
			},
			{
				path: '/parkingOrder',
				name: 'parkingOrder',
				component: () => import('../views/operate/parkingOrder.vue'),
				meta: {
				   keepAlive: true
				}
			},
			{
				path: '/parkingOrdDet',
				name: 'parkingOrdDet',
				component: () => import('../views/operate/parkingOrdDet.vue')
			},
			{
				path: '/ebikeDevice',
				name: 'ebikeDevice',
				component: () => import('../views/operate/ebike_device.vue')
			},
			{
				path: '/ebikeOrder',
				name: 'ebikeOrder',
				component: () => import('../views/operate/ebikeOrder.vue'),
				meta: {
				   keepAlive: true
				}
			},
			{
				path: '/EbikeOrderDet',
				name: 'EbikeOrderDet',
				component: () => import('../views/operate/EbikeOrderDet.vue')
			},
			{
				path: '/EbikeCL',
				name: 'EbikeCL',
				component: () => import('../views/operate/EbikeCL.vue')
			},
			{
				path: '/chargeLog',
				name: 'chargeLog',
				component: () => import('../views/operate/chargeLog.vue')
			},
			{
				path: '/admin',
				name: 'Admin',
				component: () => import('../views/System/admin.vue')
			},
			{
				path: '/CPmodel',
				name: 'CPmodel',
				component: () => import('../views/System/CPmodel.vue')
			},
			{
				path: '/loginManage',
				name: 'LoginManage',
				component: () => import('../views/System/loginManage.vue')
			},
			{
				path: '/navBar',
				name: 'NavBar',
				component: () => import('../views/System/navBar.vue')
			},
			{
				path: '/notice',
				name: 'Notice',
				component: () => import('../views/System/notice.vue')
			},
			{
				path: '/platform',
				name: 'Platfrom',
				component: () => import('../views/System/platform.vue')
			},
			{
				path: '/role',
				name: 'Role',
				component: () => import('../views/System/role.vue')
			},
			{
				path: '/script',
				name: 'Script',
				component: () => import('../views/System/script.vue')
			},
			{
				path: '/usemange',
				name: 'usemange',
				component: () => import('../views/System/use.vue')
			},
			{
				path: '/useback',
				name: 'useback',
				component: () => import('../views/operate/useback')
			},
			{
				path: '/appslide',
				name: 'appslide',
				component: () => import('../views/operate/appslide')
			},
			{
				path: '/OperRec',
				name: 'OperRec',
				component: () => import('../views/System/OperRec')
			},
			{
				path: '/editMin',
				name: 'editMin',
				component: () => import('../views/System/editMin')
			},
			{
				path: '/editPW',
				name: 'editPW',
				component: () => import('../views/System/editPW')
			},
			{
				path: '/movable',
				name: 'movable',
				component: () => import('../views/System/movable')
			},
			{
				path: '/addPlat',
				name: 'addPlat',
				component: () => import('../views/System/addPlat')
			},
			{
				path: '/Usedispose',
				name: 'Usedispose',
				component: () => import('../views/System/Usedispose')
			}
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})
router.beforeEach((to, from, next) => {
	 //next()
  var token = window.localStorage.getItem('orange_token')
  var tokenStartTime = window.localStorage.getItem('tokenStartTime')
  var timeOver = 3600*1000*24*3
  var date = new Date().getTime()
  if(token&&date - tokenStartTime>=timeOver) {
	  token = null
	  window.localStorage.removeItem('orange_token')
  }
  if(!token&&to.name !== 'login') return next('/login')
  else if(token&&to.name == 'login') return next('/index')
  else next()
})
export default router
