import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/charts.js'
import 'element-ui/lib/theme-chalk/index.css'
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css'
import elemntResize from 'element-resize-detector'
import AMap from './utils/map.js'
import axios from './utils/http.js'
import common from './utils/common.js'
import {mixin} from './utils/mixin.js'

Vue.prototype.$elemntResize = elemntResize
Vue.prototype.$AMap = AMap
Vue.prototype.$axios = axios
Vue.prototype.$common = common
Vue.config.productionTip = false
Vue.mixin(mixin)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
