import axios from "axios"
import Vue from 'vue'
const service = axios.create({
  baseURL: 'https://orgcms.orangeevcharge.com/api/web/',
  //baseURL: 'http://www.org.com/api/web/',
  withCredentials: false,
  timeout: 30000
})
// 添加请求拦截器

 var date, tokenStartTime, onlineTime, number, timeOver, ValidTime
  number = 1
  timeOver = 3600*24*3
  ValidTime = 14400
  // 清除token
  function clearToken(){
	 window.localStorage.clear()
	Vue.prototype.$router.push({
	  name: 'Login'
	})     
  }
service.interceptors.request.use(function(config) {
      // 在发送请求之前做些什么
      //console.log('config',config)
       config.headers.Authorization = `Bearer ${window.localStorage.getItem('orange_token')}`
      // config.headers['Access-Control-Allow-Origin'] = `*`
	  /* 获取当前时间和记录时间(tokenStartTime)作比较，超过了三天就不会再自动刷新token*/
	   date = new Date().getTime()
	   tokenStartTime = JSON.parse(window.localStorage.getItem('tokenStartTime'))
	   onlineTime = ((date - tokenStartTime)/1000).toFixed(0)
	  // console.log(onlineTime>=ValidTime&&onlineTime<timeOver)
	  if(onlineTime>=(ValidTime-3000)&&onlineTime<timeOver&&number==1){
		number++  
		service.post('auth/refresh').then((res)=>{
			   //console.log(res)
			   var data = res.data
			   if(data.Code=='0'){
			   	window.localStorage.setItem('orange_token',data.Data.access_token)
			   	window.localStorage.setItem('tokenStartTime',new Date().getTime())
			   }else {
				console.log(data.Msg) 
				clearToken()
			   }  
		}).catch((err)=>{
			console.error(err)
			clearToken()
		})
		 if(!window.sessionStorage.getItem('orange_config')) {
			var userData = JSON.parse(window.localStorage.getItem('userData'))
			var oid = userData.userinfo.o_id
			var type = ['role','station','mount_user','chargingRules']
			if(userData.userinfo.is_sys=='1') type.push('yys')
			Vue.prototype.$common.config(type,oid).then((res)=>{
				var data = res.data
				if(data.Code=='0'){
				  window.sessionStorage.setItem('orange_config', JSON.stringify(data.Data))
				}else console.warn(data.Msg)
			}).catch(err=>console.error(err))   
		 }  
	  }
	   
      return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });
  
service.interceptors.response.use(function(res){
  //响应拦截
  if(res.data.Code==40003||res.data.Code==40002){
		document.cookie = 'userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
		clearToken()
		// Vue.prototype.$message.error('请重新登录')
  }else return res
  
},function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
})
export default service
