import axios from "./http.js"
// 获取用户信息
let userInfo = function() {
  return new Promise((outRes, outReq) => {
    axios({
      method: 'post',
      url: 'auth/userinfo'
    }).then((res) => {
      //console.log(res)
      outRes(res)
    }).catch((req) => {
      outReq(req)
    })
  })
}
// 获取基础信息
function config(type,oid){
	return new Promise((outRes, outReq)=>{
		axios.post('auth/config',{
			type: type,
			o_id: oid
		}).then((res)=>{
			outRes(res)
		}).catch((err)=> outReq(err))
	})
}
// 图片上传到服务器
function upImg(FD){
	return new Promise((outRes, outReq)=>{
		axios.post('upload/putImg',FD).then((res)=>{
			outRes(res)
		}).catch((err)=> outReq(err) )
	})
}
// 删除服务器图片
function removeImg(file){
	return new Promise((outRes, outReq)=>{
		axios.post('upload/delImg',{
			file_name: file
		}).then((res)=>{
			outRes(res)
		}).catch((err)=> outReq(err) )
	})
}
// 图片上传到阿里云
function upImgOss(FD){
	return new Promise((outRes, outReq)=>{
		axios.post('upload/putImgOss',FD).then((res)=>{
			outRes(res)
		}).catch((err)=> outReq(err) )
	})
}
// 删除阿里云图片
function removeImgOss(file){
	return new Promise((outRes, outReq)=>{
		axios.post('upload/delImgOss',{
			file_name: file
		}).then((res)=>{
			outRes(res)
		}).catch((err)=> outReq(err) )
	})
}
// 获取指定cookie
let getCookie = function(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
// 赋予cookie, cookie 的名字（cname），cookie 的值（cvalue），以及知道 cookie 过期的天数（exdays）
let setCookie = function(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
let timer1 = null; //防抖，
let timer2 = null; //节流
const Debounce = (fn, t) => {
  let delay = t || 500;
  return function() {
    let args = arguments;
    // let timer1 = null
     //console.log(timer1);
    if (timer1) {
      clearTimeout(timer1);
    }
    timer1 = setTimeout(() => {
      fn.apply(this, args);
      timer1 = null;
    }, delay);
  }
};
// 使用
/*
Debounce(() => {
    //要执行的函数
}, 200)() */

/**
 * 函数节流
 * @param fn
 * @param interval
 * @returns {Function}
 * @constructor
 */
const Throttle = (fn, t) => {
  let last;
  let interval = t || 500;
  return function() {
    let args = arguments;
    let now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer2);
      timer2 = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, interval);
    } else {
      last = now;
      fn.apply(this, args);
    }
  }
};
// 表单清空
const resetField = function(formName, vm) {
  // vm为当前实例
  vm.$refs[formName].resetFields()
}

/* 跳转外链下载 */
function postExcelFile(params, url) {
  var website = 'https://orgcms.orangeevcharge.com/api/web'
  //var website = 'https://orgcms.orangeevcharge.com/api/web'
  if(url) url = website.concat(url)
  var form = document.createElement("form");
  form.style.display = 'none';
  form.action = url;
  form.method = "post";
  document.body.appendChild(form);
  params.token = `${window.localStorage.getItem('orange_token')}`
  for (var key in params) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = params[key];
      form.appendChild(input);
  }
  form.submit();
  form.remove();
}
// 时间补零
function ismm(t){return t<10?`0${t}`:t}
// 格式化时间
function formatTime(time){
   if(time!==null){
    var year = time.getFullYear()
    var Mon = time.getMonth()+1
    var date = time.getDate()
    var hours = time.getHours()
    var min = time.getMinutes()
    var ss = time.getSeconds()
    return `${ismm(year)}-${ismm(Mon)}-${ismm(date)} ${ismm(hours)}:${ismm(min)}:${ismm(ss)}`
   }else{
     return ''
   }
}
// 获取图表数据
function getTimeChart(params){
	if(typeof(params)!=='object') return console.error('params必须是object类型')
	var data = {}, key
	// 筛选未填的参数
	for(key in params){
		if(params[key]) data[key] = params[key]
	}
	return new Promise((outRes,outReq)=>{
		axios.post('home/timeChart',data).then((res)=>{
			var data = res.data
			if (data.Code == '0') {
				outRes(data.Data)
			} else {
				console.warn(data.Msg)
			}
		}).catch((err) => {
			console.error(err)
		})
	})
}
// 获取新增用户数据
function getVipChart(params){
	if(typeof(params)!=='object') return console.error('params必须是object类型')
	return new Promise((outRes,outReq)=>{
		axios.post('home/addVipChart',{
			datetimes: params.datetimes
		}).then((res)=>{
			var data = res.data
			if (data.Code == '0') {
				outRes(data.Data)
			} else {
				console.warn(data.Msg)
			}
		}).catch((err) => {
			console.error(err)
		})
	})
	
}

export default {
  userInfo,
  getCookie,
  setCookie,
  Debounce,
  Throttle,
  resetField,
  postExcelFile,
  formatTime,
  config,
  upImgOss,
  removeImgOss,
  upImg,
  removeImg,
  getTimeChart,
  getVipChart
}
