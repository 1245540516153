 export const mixin = {
	methods: {
		// 获取基本信息
		async getInfo(oid,type){
			if(!Array.isArray(type)) return console.error('getInfo里type传递的参数必须是Array类型')
			var cc
			//console.log(type)
			await this.$common.config(type,oid).then((res)=>{
				//console.log(res)
				var data = res.data
				if(data.Code=='0'){
				  cc = data.Data
				}else this.$message.warning(data.Msg)
			}).catch(err=>this.$message.error(err))
			return cc
		},
		handleCurrentChange(val) {
			//console.log(`当前页: ${val}`)
			this.currentPage = parseInt(val)
			this.getList()
		},
		changeType(val){
			// this.currentPage = parseInt(val)
		},
		Exceeded() {
			this.currentPage = Math.ceil(this.currentPage > this.total / this.per_page ?  this.total / this.per_page : (this.currentPage<= 0 ? 1 : this.currentPage))
			this.getList()
		}
	}
}