import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 判断侧边栏是否折叠
    isCollapse: false,
	userData: '',
	chargetype:[
	    {
		  key: 1,
		  text: '微信充电'  
		},
		{
		  key: 2,
		  text: '卡充电'  
		},
		{
		  key: 3,
		  text: '蚂蚁充电'  
		},
		{
		  key: 4,
		  text: '联行逸充'  
		},
		{
		  key: 5,
		  text: '车主邦'  
		},
		{
		  key: 6,
		  text: '支付宝'  
		},
		{
		  key: 7,
		  text: '恒大'  
		},
		{
		  key: 8,
		  text: '蔚景云'  
		},
		{
		  key: 9,
		  text: '小桔'  
		},
		{
		  key: 10,
		  text: 'ETC充电'  
		},
		{
		  key: 11,
		  text: '鹏城充'  
		},
		{
		  key: 12,
		  text: 'VIN充电'  
		},
		{
		  key: 13,
		  text: '八桂充'  
		},
		{
		  key: 14,
		  text: '贵州省监管平台'  
		}
		]
  },
  mutations: {
    // 改变侧边栏折叠事件
    ToggleCollapse (state) {
      state.isCollapse = !state.isCollapse
    },
	getUserData(state,data){
	  state.userData = data
	}
  },
  actions: {
  },
  modules: {
  }
})
